@media (max-width: 1640px) {
  .AL_wrap2 {
    padding: 0 15px;
  }

  .middle_panel {
    width: 40%;
    padding: 0 20px;
  }

  .left_panel,
  .right_panel {
    width: 30%;
  }

  .middlePage_bg.h-100 {
    max-width: 430px;
    margin: 0 auto;
  }

  .middle_panel .bottom_checklist {
    display: none;
  }

  .verify_bottom {
    max-width: 100%;
    position: initial;
  }

  .bottom_checklist li {
    padding: 0px 13px;
    font-size: 18px;
  }

  .social_icons img {
    max-height: 34px;
  }

  .download_app_icon a img {
    max-height: 50px;
  }

  .left_slider {
    padding-right: 20px;
  }
}

@media (max-width: 1199px) {
  .download_app_icon img {
    max-width: 100%;
  }

  .middle_panel {
    width: 100%;
    padding: 0 20px;
  }

  .left_panel,
  .right_panel {
    width: 25%;
  }

  .bottom_checklist li {
    padding: 0 10px;
    font-size: 14px;
    width: 33.33%;
  }

  .check-offer {
    font-size: 15px;
  }

  .download_app_icon h6 {
    width: 100%;
    text-align: left;
  }

  .download_app_icon {
    display: flex;
    flex-wrap: wrap;
  }

  .login_logo {
    padding-bottom: 15px;
  }

  .download_app_icon a img {
    max-height: 34px;
  }

  .right_panel,
  .left_panel {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .allCotest_tabScroll {
    height: calc(100vh - 250px);
  }

  .middle_panel {
    width: 100%;
    padding: 0;
  }

  .AL_wrap2 {
    padding: 0;
  }

  .bottom-button-false {
    height: calc(100% - 80px);
  }
}

@media (max-width: 768px) {
  .transaction_scroll {
    height: calc(100vh - 190px);
    overflow-y: auto;
  }

  .left_panel,
  .right_panel {
    display: none !important;
  }

  .middle_panel {
    width: 100%;
    padding: 0;
    max-width: 430px;
    margin: 0 auto;
  }

  .AL_wrap2 {
    padding: 0;
  }

  .siginin_page .slick-slide img {
    max-height: 274px;
  }

  .verify_bank .custom-file-input::before,
  .custom-file-input::before {
    text-indent: 48px;
    background-position: 50px center;
  }

  .kyc_card .form-control.custom-file-input {
    padding: 0;
  }
}

@media (max-width: 430px) {
  .remain_money {
    font-size: 12px;
  }

  .avail_balance h5 {
    font-size: 12px;
  }

  .all_contest_tabs_style .my-tearms-tabs-custome button {
    font-size: 12px;
    padding: 7px;
  }

  .create-contest.bg-white {
    padding: 18px 18px;
  }

  .recive_point_card figure {
    flex: 0 0 38px;
    max-width: 38px;
  }

  .matchPlayerScroll {
    height: calc(100vh - 315px);
  }

  .contest-user-list {
    padding: 14px 13px 10px;
  }

  .match-wise-card .accordion-item {
    padding: 10px 0;
  }

  .filter-canvas .btn-green {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0px;
  }

  .custome_table .table tr td {
    padding: 6px;
  }

  .save_tearm_content .custome_table {
    height: calc(100vh - 148px);
  }

  .recive_point_card figcaption span {
    font-size: 12px;
  }

  .my-tearms-tabs .custome_table {
    height: calc(100vh - 300px);
  }

  .text-dark-green,
  .not-announce_tag {
    padding-left: 13px;
    font-size: 13px;
  }

  .my-tearms-tabs-custome .nav-link {
    font-size: 12px;
  }

  .team_head {
    padding: 0 16px;
  }

  .top_header {
    padding: 19px 0;
  }

  .allCotest_tabScroll {
    height: calc(100vh - 304px);
  }

  .scorecard .accordion-item {
    border-radius: 0px;
  }

  .sort-by-sort .entry {
    padding: 4px 5px;
  }

  .sort-by-sort .entry {
    margin-right: 3px;
  }

  .btn {
    font-size: 15px;
    padding: 10px 15px;
  }

  .winning_tabs {
    height: calc(100vh - 323px);
  }

  .rank_data {
    height: calc(100vh - 100%);
  }

  .leaderboard_rank_data {
    height: calc(100% - 0px);
  }

  .contest-canvas .offcanvas-title {
    font-size: 22px;
  }

  .back_arrow a {
    font-size: 14px;
  }

  .back_arrow a img {
    max-width: 14px;
    margin-right: 8px !important;
  }

  a.notification.ms-3 {
    margin-left: 10px !important;
  }

  .top_header {
    padding: 20px 0;
  }

  .kyc_card {
    padding: 0 15px;
  }

  .profile_offcanvas {
    max-width: 310px;
  }

  .offcanvas-body {
    overflow-x: hidden;
  }

  .otp_main {
    padding: 30px 15px;
  }

  .middlePage_bg .px-4,
  .middlePage_bg .p-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .match_time {
    padding: 5px 8px;
    font-size: 11px;
  }

  .team_name .team_icon {
    width: 35px;
    height: 35px;
    flex: 0 0 35px;
    height: 35px;
  }

  .bottom_menu {
    width: 100%;
  }

  .bottom_menu ul li {
    width: auto;
  }

  .bottom_menu ul {
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
  }

  .refer_coupon p {
    padding: 0 16px;
  }

  .refer_friend p br {
    display: none;
  }

  .px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .faq h3 {
    margin-bottom: 10px;
  }

  .faq button {
    padding: 8px 0;
  }

  .rewards h4 span,
  .rewards h2 {
    font-size: 24px;
  }

  .fs-16 {
    font-size: 14px;
  }

  .my_contest {
    padding: 10px;
  }

  .contest_heading h2 {
    font-size: 14px;
  }

  .match_time {
    min-width: 80px;
  }

  .team_name h4 span {
    display: block;
    font-size: 10px;
    margin-top: 0px;
  }

  .home_menu img {
    max-height: 40px;
  }

  .bottom_menu ul li img::not(.home_menu img) {
    max-height: 20px;
  }

  .bottom_menu ul li a img {
    max-height: 20px;
  }

  .bottom_menu ul li.home_menu a img {
    max-height: 36px;
  }

  .bottom_menu ul li a {
    font-size: 12px;
  }

  .myoffer_main h5 {
    font-size: 16px;
  }

  .get-friend td {
    font-size: 12px;
  }

  .transaction_scroll {
    height: calc(100vh - 171px);
    overflow-y: auto;
  }

  .btn {
    font-size: 13px !important;
  }

  .custome_table thead th {
    font-size: 12px;
  }

  .used_team_member ul li {
    background: #fff;
    margin: 3px;
    width: 20px;
    height: 20px;
    padding: 3px;
  }

  .cricket_ground {
    padding: 12px 20px;
  }

  .AllRounder {
    margin-top: 20px;
  }

  .bowler {
    margin-top: 0px;
  }

  /* .cricket_ground { 
  height: calc(100% - 3px);
} */

  .custome_table thead th:first-child,
  .custome_table tbody td:first-child {
    padding-left: 15px !important;
  }

  .custome_table thead th:last-child,
  .custome_table tbody td:last-child {
    padding-right: 15px !important;
  }

  .used_team_member .league_team_count li {
    padding: 4px;
    height: 100%;
  }

}

@media (max-width: 575px) {
  .bottom-button-false {
    height: calc(100% - 80px) !important;
  }

  .discount_icon {
    width: 60px;
    height: 60px;
    padding: 15px;
  }

  .discount-inner-sec {
    width: calc(100% - 70px);
  }

  .full_page_scroll {
    height: calc(100% - 118px);
  }

  .main_logo img {
    max-width: 120px;
  }

  .siginin_page p {
    margin-bottom: 10px;
  }

  .custom_check {
    font-size: 14px;
  }

  .siginin_page form {
    padding: 0 15px;
    margin-bottom: 10px;
  }

  .sign-in-check .form-check-input {
    margin-right: 5px;
  }

  /* league_session */

  .join_btn {
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 8px 15px;
    width: 110px;
  }

  .season_league_ctg strong {
    font-size: 16px;
  }
}

@media (max-width: 479px) {
  .scorecard .accordion-item {
    padding: 15px 20px 10px;
  }

  .refer_coupon p {
    font-size: 14px;
  }

  .refer_coupon p span {
    font-size: 14px;
  }

  .back_arrow {
    font-size: 12px;
  }
}