 img {
   max-width: 100%;
 }

 body {
   font-family: 'Montserrat', sans-serif !important;
   background-color: #000304;
   color: #b2b2b2;
   font-size: 14px;
   list-style: 24px;
 }

 h1 {
   font-family: 'Kanit', sans-serif;
 }

 h2 {
   font-weight: bold;
   margin-bottom: 10px;
 }

 .btn,
 .btn:disabled {
   background-color: #11FFBD;
   border-color: #11FFBD;
   border-radius: 8px;
   color: #000;
   /* font-size: 16px;
   font-weight: 500;
   padding: 15px; */
 }

 a:hover {
   color: #F7B32B;
 }

 .btn:hover {
   color: #000;
   background: linear-gradient(356deg, #11FFBD, #00946a);
   border-color: #00946a;
 }

 .heading {
   margin-bottom: 36px;
   text-align: center;
 }

 .hungama_game {
   margin: 120px 0;
   position: relative;
   padding: 124px 0;
 }

 .hungama_game:before {
   position: absolute;
   content: "";
   height: 6px;
   background-color: #11FFBD;
   left: 0;
   right: 0;
   max-width: 940px;
   margin: auto;
   top: 0;
 }

 .hungama_game:after {
   position: absolute;
   content: "";
   height: 6px;
   background-color: #11FFBD;
   left: 0;
   right: 0;
   margin: auto;
   max-width: 940px;
   bottom: 0;
 }

 .heading p {
   font-size: 22px;
   color: #fff;
   margin: 0;
 }

 .heading h2 {
   color: #fff;
   font-size: 38px;
 }

 header {
   padding: 40px 0;
   position: absolute;
   width: 100%;
   z-index: 9;
 }

 .nav-link {
   color: #fff;
   font-size: 16px;
 }

 .navbar-nav .nav-link.active,
 .navbar-nav .show>.nav-link,
 .nav-link:hover {
   color: #11ffbd;
 }

 /* header */
 .banner {
   padding: 220px 0 200px;
   background: #040e38 url(../images/inner_body_bg.png) no-repeat center top / cover;
   color: #b2b2b2;

 }

 .caption h1 {
   color: #fff;
   font-size: 75px;
   font-weight: bold;
   line-height: 1;
   margin-bottom: 20px;
 }

 .banner .slick-dots {
   position: relative;
   top: 50px;
 }

 .caption p {
   color: #fff;
   font-size: 25px;
 }

 .slick-dots {
   display: flex !important;
   align-items: center;
   justify-content: center;
 }

 .slick-dots li {
   list-style: none;
   padding: 5px;
 }

 .slick-dots li button {
   font-size: 0;
   width: 6px;
   height: 12px;
   border-radius: 100%;
   border: 0;
   display: block;
 }

 .slick-dots li.slick-active button {
   background-color: #11FFBD;
 }

 .why_game {
   /* background: url(../images/offer_bg.jpg) no-repeat center top / cover; */
   margin-top: -115px;
 }

 .download_app .box {
   border-radius: 20px;
   height: 100%;
   background: linear-gradient(52deg, #0fb59b, #920b08);
 }


 .download_app .box h3 {
   font-size: 25px;

   color: #000000;
   min-height: 54px;
 }

 .coming_soon_text {
   color: red;
   font-size: 20px;
 }

 .download_app .box h3 span {
   font-size: 14px;

 }

 .download_app .box input {
   padding: 15px;
   font-size: 16px;
 }


 .hungama_game .box {
   padding: 36px;
   border-radius: 40px;
   transition: 0.4s ease all;
 }

 .coming_soon_text {
   color: red;
   font-size: 20px;
 }

 .hungama_game .box ul {
   margin: 0;
   padding: 0;
   list-style: inside;
 }

 .hungama_game .box:hover {
   background: linear-gradient(1deg, #010f14, #263642);
 }

 .hungama_game .box h4 {
   font-size: 20px;
   font-weight: bold;
 }

 .hungama_game .box .icon {
   height: 160px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 28px;
 }

 .hungama_game .box .text {
   text-align: center;
 }

 .hungama_game .box p {
   color: #fff;
   font-size: 14px;
   padding: 0 15px;
 }

 .our_game .slick-slide img {
   width: 100%;
 }

 .text-green {
   color: #11FFBD;
 }

 /**RR**/
 .our_game .slick-dots {
   margin-top: 20px;
 }

 /* SM */
 .offers_main {
   background: linear-gradient(359deg, #000000, #264c55);
   border-radius: 40px;
   width: 100%;
   padding: 54px 20px 30px;
   height: 100%;
 }

 .offers {
   background-image: url('../images/download_bg.jpg');
   background-size: cover;
   padding: 94px 0;
 }

 .offers_main h3 {
   font-size: 20px;
   margin-top: 36px;
   text-align: center;
   color: #fff;
 }

 .offers_main img {
   max-height: 250px;
 }

 /* footer */


 .footer h4 {
   font-size: 18px;
   color: #fff;
   font-weight: bold;
   color: #11FFBD;
 }

 .footer p {
   font-size: 14px;
   color: #B3BAD1;
 }

 .footer ul {
   list-style: none;
   padding: 0;
 }

 .footer ul li a {
   font-size: 14px;
   color: #B3BAD1;
   text-decoration: none;
   margin-bottom: 0;
   padding: 5px 0;
   display: block;
 }

 .social_icons img {
   max-height: 40px;
 }

 .footer ul li a:hover {
   color: #fff;
 }

 .footer {
   padding: 60px 0 24px 0;
   background-color: #0B1823;
 }

 .fairplay {
   display: flex;
   align-items: center;
   border-radius: 10px;
   margin-bottom: 12px;
   background: #15658E;
   min-height: 64px;
 }

 .fairplay h5 {
   font-size: 16px;

   margin: 0;
   color: #fff;
 }

 .fairplay img {
   padding: 10px;
   max-width: 46px;
 }

 .desclaimer p {
   font-size: 14px;
   color: #B2B2B2;
 }

 .desclaimer h6 {
   font-size: 16px;
   margin-bottom: 0;
   font-weight: 600;
 }

 .desclaimer {
   padding: 20px 0 15px 0;
   background-color: #344452;
 }




 /* footer */

 /* murli-css */
 .frequent-sec .accordion-button:not(.collapsed),
 .frequent-sec .accordion-item,
 .frequent-sec .accordion-button {
   background-color: transparent;
   border-bottom-left-radius: 0 !important;
 }

 .frequent-sec .accordion-button:not(.collapsed) {
   color: #fff;
   box-shadow: none;
 }

 .frequent-sec .accordion-button:focus {
   box-shadow: none;
 }

 .frequent-sec .accordion-button {
   color: #fff;
   padding: 20px 0;
 }

 .frequent-sec .accordion-button::after {
   filter: brightness(0) invert(1);
 }

 .frequent-sec .accordion-button,
 .frequent-sec .accordion-item:first-of-type,
 .frequent-sec .accordion-item:last-of-type {
   border-radius: 0px;
 }

 .frequent-sec .accordion-item {
   border-left: none;
   border-right: none;
   border-bottom: none;

 }

 .frequent-sec .accordion {
   max-width: 930px;
   margin: 0 auto;
 }

 .frequent-sec .accordion-item:first-of-type {
   border-top: none;
   padding-top: 0;
 }

 .frequent-sec .accordion-item:last-of-type {
   border-bottom: none;
   padding-bottom: 0;
 }

 .frequent-sec .accordion-button {
   font-size: 22px;
   padding: 24px 0px;
   border-bottom: 1px solid #556572;

 }

 .slick-dots {
   padding: 0;
 }

 .frequent-sec .accordion-body {
   color: #ffffff;
   border-top: none;
   border: none;
   padding: 15px 0px;
 }

 .frequent-sec {
   padding: 84px 0;
 }


 .frequent-sec::after {
   background-image: url(../images/freq-right.png);
   width: 51px;
   right: 0;
   height: 100%;
   background-repeat: no-repeat;
   top: 64px;
 }

 .download-sec {
   padding: 70px 0 80px 0;
   background-image: url('../images/download_bg.jpg');
 }

 .download-inner h4 {
   font-size: 22px;
   color: #fff;

   margin-bottom: 19px;
 }

 .download-inner p {
   color: #b2b2b2;
 }

 .download-inner li {
   margin-bottom: 36px;
 }

 .download-sec .row {
   max-width: 990px;
   margin: 0 auto;
 }

 .download-sec::after {
   right: 0;
   background-image: url(../images/download-shape-right.png);
   height: 325px;
   width: 902px;
   top: 0;
   z-index: 1;
 }



 .store img {
   width: 100%;
 }

 .download-sec figure {
   position: relative;
   z-index: 2;
 }

 .download-step li {
   list-style: none;
 }

 .responsible_page {
   padding: 132px 0 24px 0;
   background: #0B1823;
 }

 .responsible_page * {
   color: #fff;
 }

 .responsible_page h2 {
   font-size: 24px;
 }

 .responsible_page p {
   color: #ddd;
   font-size: 16px;
   margin-bottom: 15px;
 }

 .text_wrapper {
   background: #344452;
   border-radius: 24px;
   padding: 24px 32px;
 }


 .prize_bacup {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 15px 0 10px 0;
   color: #fff;
 }

 .number_visite {
   border: 1px solid #ddd;
   padding: 5px 10px;
   text-align: left;
   flex: 1;
   margin-left: 20px;
   border-radius: 5px;
 }

 .radion_btn input {
   margin-right: 6px;
   vertical-align: middle;
   position: relative;
   top: -1px;
 }

 .radion_btn label {
   width: 100%;
   padding: 10px 0;
 }

 .radion_btn {
   line-height: 1;
 }




 .stats-accordian .collapse:not(.show) {
   display: block !important;
 }

 .score-over-sec .btn {
   font-size: 14px;
   color: #fff !important;
 }

 .my-tearms-tabs .accordion-item:last-of-type {
   border-radius: 0;
 }

 .qr_code {
   max-width: 140px;
 }

 .our-game-figure {
   padding: 0 24px;
 }

 .our_game_outer h4 {
   text-align: center;
 }

 .our_game {
   padding-bottom: 32px;
 }

 .our_game_outer h4 a {
   font-size: 30px;
   text-decoration: none;
   color: #F7B32B;
   font-weight: bold;
 }

 .our_game_outer {
   margin-bottom: 32px;
 }

 .our-game-figure {
   padding: 0 24px;
 }

 .our_game_outer h4 {
   text-align: center;
 }

 .with_more_to_follow {
   font-size: 20px;
   font-weight: normal;
 }



 .our_fantasy {
   padding: 68px 0 100px 0;
 }

 .fantasy_screen {
   text-align: center;
   margin-bottom: 0;
   background: #000;
   border: 1px solid #707070;
   border-radius: 22px;
   overflow: hidden;
   padding: 12px;
 }

 .fantasy_screen img {
   border-radius: 22px;
   width: 100%;
   margin: auto;
   opacity: 0.5;
   transition: all .3s;
   -webkit-transition: all .3s;
 }

 .our_fantasy .slick-current .fantasy_screen img {
   opacity: 1;
 }

 /* 
.our_fantasy .slick-list:before {
  content: "";
  /* background-color: #000; 
  border-radius: 32px;
  border: 1px solid #707070;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
} 
.our_fantasy .slick-list {
  position: relative;
}*/

 .our_fantasy .slick-slide {
   padding: 0 15px;
 }



 .team_difinebet {
   flex: 0 0 94px;
 }

 .best_vlue_center {
   flex: 1;
   padding: 0 10px;
 }

 .rcbT.team_difinebet .d-flex {
   justify-content: flex-end;
 }

 .league_crete_profile {
   height: calc(100vh - 150px);
   overflow-y: auto;
 }


 .league_detail_tabs.my-tearms-tabs-custome li .nav-link {
   padding: 10px 20px;
 }



 .league_detail_tabs.my-tearms-tabs-custome li .nav-link {
   padding: 10px 20px;
 }

 .genral_user_card {
   background: #344452;
   border-radius: 8px;
   margin: 0 5px;
   text-align: center;
   position: relative;
   overflow: visible;
 }

 .genral_user_card figure {
   text-align: center;
   margin: 0;
   padding: 15px 10px;
 }

 .genral_user_card figure img {
   margin: auto;
   width: 62px;
   height: 62px;
   border-radius: 100px;
   /* border: 1px solid #e4736d; */
   border: 1px solid #ffffff;
 }

 .genral_user_card figcaption strong {
   display: block;
 }

 .genral_user_card figcaption {
   background: #5B6F84;
   padding: 10px;
   color: #fff;
   border-radius: 0 0 8px 8px;
 }

 .genral_user_card .crs {
   background: #11ffbd;
   color: #000;
   padding: 2px 10px;
   border-radius: 100px;
   min-width: 41px;
   display: inline-block;
   font-weight: 500;
   position: absolute;
   left: 10px;
   top: 5px;
 }


 .genral_transfer_head {
   padding: 30px 24px;
 }

 .league-details_height {
   height: calc(100vh - 194px) !important;
   overflow-y: auto;
   background: #13212D;
   padding-bottom: 20px;
 }

 .point_adding_right {
   flex: 0 0 100px;
   max-width: 100px;
 }

 .fs-12 {
   font-size: 12px;
 }

 .league_point_history {
   border-radius: 16px;
   overflow: hidden;
   background: #344452;
   margin-bottom: 15px;
 }

 .rugby_icon {
   height: 36px;
   width: 36px;
   margin-bottom: -13px;
   margin-left: 11px;
 }